import classNames from "classnames";
import React from "react";

export const Button = ({ className, text, onClick, disabled }) => {
  return (
    <button
      className={classNames("btn", className)}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
};
